import '@app/shared/extensions/moment.extension';

import { Pessoa } from '@app/abstraction/domain/entities/cadastros/pessoa/pessoa.entity';
import { LancamentoParcela } from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-parcela.entity';
import { Pedido } from '@app/abstraction/domain/entities/vendas/pedido/pedido.entity';
import { ClienteListagem } from '@app/abstraction/domain/interfaces/cadastros/cliente/cliente-listagem.interface';
import * as moment from 'moment';

export class Cliente extends Pessoa {
  ativo: boolean;
  cartaoNumero: string;
  cartaoSenha: string;
  id: string;
  clienteSituacaoId: string;
  clienteTipo: any;
  descontoPercentual: number;
  limite: number;
  limiteCarencia: number;
  limiteDiaCorte: number;
  limiteDiaVencimento: number;
  pontuacaoAcumulada: number;
  observacao: string;
  pedidos: Pedido[];
  lancamentosParcela: LancamentoParcela[];
  lastModifiedOn: Date;
  createdOn: Date;
  createdBy: string;
  nomeCreatedBy: string;
  planoConta3Id: string;
  sequencia: number;
  limiteUtilizado: number;
  limiteSaldo: number;

  static from(json: any = {}): Cliente {
    const cliente = new Cliente();
    cliente.ativo = json.ativo;
    cliente.cartaoNumero = json.cartaoNumero?.trim();
    cliente.cartaoSenha = json.cartaoSenha?.trim();
    cliente.pontuacaoAcumulada = json.pontuacaoAcumulada;
    cliente.id = json.id;
    cliente.clienteSituacaoId = json.clienteSituacaoId;
    cliente.clienteTipo = json.clienteTipo;
    cliente.descontoPercentual = json.descontoPercentual;
    cliente.limite = json.limite;
    cliente.limiteCarencia = json.limiteCarencia;
    cliente.limiteDiaCorte = json.limiteDiaCorte;
    cliente.limiteDiaVencimento = json.limiteDiaVencimento;
    cliente.limiteUtilizado = json.limiteUtilizado;
    cliente.limiteSaldo = json.limiteSaldo;
    cliente.observacao = json.observacao?.trim();
    cliente.pedidos = json.pedidos;
    cliente.lancamentosParcela = json.lancamentosParcela;
    cliente.lastModifiedOn =
      json.lastModifiedOn != null ? moment(json.lastModifiedOn).toDate() : null;
    cliente.createdOn =
      json.createdOn != null ? moment(json.createdOn).toDate() : null;
    cliente.createdBy = json.createdBy?.trim();
    cliente.pessoaFrom(json);
    return cliente;
  }
  toAPI() {
    this.preencherUfEnderecos();
    if (this.nascimento)
      this.nascimento = moment(this.nascimento).toLocalOnlyDate();
  }

  toListagem() {
    return <ClienteListagem>{
      ativo: this.ativo,
      clienteSituacaoId: this.clienteSituacaoId,
      clienteTipo: this.clienteTipo,
      createdBy: this.createdBy,
      createdOn: this.createdOn,
      documento: this.documento,
      id: this.id,
      nascimento: this.nascimento,
      nomeFantasia: this.nomeFantasia,
      nomeRazaoSocial: this.nomeRazaoSocial,
      pessoaTipo: this.pessoaTipo,
      sequencia: this.sequencia,
    };
  }
}
