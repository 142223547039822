import '@app/shared/extensions/string.extension';

import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Produto} from '@app/abstraction/domain/entities/produtos/produto/produto.entity';
import {EmpresaFacade} from '@app/abstraction/domain/facades/cadastros/empresa/empresa.facade';
import {AreaEstoqueFacade} from '@app/abstraction/domain/facades/estoque/area-estoque/area-estoque.facade';
import {FamiliaFacade} from '@app/abstraction/domain/facades/produtos/familia/familia.facade';
import {MarcaFacade} from '@app/abstraction/domain/facades/produtos/marca/marca.facade';
import {ProdutoVariacaoCorFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-cor/produto-variacao-cor.facade';
import {ProdutoVariacaoReferenciaFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-referencia/produto-variacao-referencia.facade';
import {ProdutoVariacaoTamanhoFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-tamanho/produto-variacao-tamanho.facade';
import {ProdutoFacade} from '@app/abstraction/domain/facades/produtos/produto/produto.facade';
import {SecaoFacade} from '@app/abstraction/domain/facades/produtos/secao/secao.facade';
import {TabelaNutricionalFacade} from '@app/abstraction/domain/facades/produtos/tabela-nutricional/tabela-nutricional.facade';
import {UnidadeComercialFacade} from '@app/abstraction/domain/facades/produtos/unidade-comercial/unidade-comercial.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ProdutoSharedFormComponent} from '@app/shared/components/shared-forms/produto-shared-form/produto-shared-form.component';
import {ProdutoTipo} from '@app/shared/enums/produto-tipo.enum';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-produto-cadastro-rapido-modal',
  templateUrl: './produto-cadastro-rapido-modal.component.html',
  styleUrls: ['./produto-cadastro-rapido-modal.component.scss'],
})
export class ProdutoCadastroRapidoModalComponent extends FormBaseComponent
    implements AfterViewInit {
  @ViewChild('formComponent') formComponent: ProdutoSharedFormComponent;
  @Input() mostrarModal: boolean;
  @Input() produto: Produto;
  @Output() aoSalvar = new EventEmitter<Produto>();
  @Output() aoCancelar = new EventEmitter();

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private loadingService: NgxUiLoaderService,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private empresaFacade: EmpresaFacade,
      private familiaFacade: FamiliaFacade,
      private produtoFacade: ProdutoFacade,
      private produtoVariacaoCorFacade: ProdutoVariacaoCorFacade,
      private produtoVariacaoReferenciaFacade: ProdutoVariacaoReferenciaFacade,
      private produtoVariacaoTamanhoFacade: ProdutoVariacaoTamanhoFacade,
      private secaoFacade: SecaoFacade,
      private tabelaNutricionalFacade: TabelaNutricionalFacade,
      private unidadeComercialFacade: UnidadeComercialFacade,
      private areaEstoqueFacade: AreaEstoqueFacade,
      private authFacade: AuthFacade,
      private marcaFacade: MarcaFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarProduto(novoProduto: Produto) {
    return this.produtoFacade.adicionar(novoProduto).catch((e: any) => {
      this.loadingService.stop();
      return this.errorMessagesFacade.mostrarMensagens(e);
    });
  }
  private mostrarSucessoAoSalvar(produto: Produto) {
    this.toastrService.success(
        this.formComponent?.produtoForm?.mensagemSucessoAoSalvar);
    this.aoSalvar.emit(produto);
    this.formComponent.fecharGeradorEanModal();
    this.fecharModal();
    this.loadingService.stop();
  }
  async salvarProduto() {
    try {
      this.loadingService.start();

      if (!this.formComponent.produtoForm.isValid()) {
        this.loadingService.stop();
        return;
      }

      const produto = this.formComponent.preencherObjeto();

      const resultado = await this.adicionarProduto(produto)

      if (resultado) this.mostrarSucessoAoSalvar(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar produto.');
      this.loadingService.stop();
    }
  }
  async verificarSePrecisaGerarEan() {
    if (!this.formComponent.produtoForm.isValid()) {
      this.errorMessagesFacade.mostrarCamposInvalidos(
          this.formComponent.produtoForm);
      return;
    }

    const produto = this.formComponent.preencherObjeto();
    const todasVariacoesPossuemCodigoBarras =
        produto.variacoes.every((variacao) => variacao.codigoBarras);

    if (produto.produtoTipo === ProdutoTipo.Simples &&
        !produto.variacoes.first().codigoBarras) {
      this.formComponent.abrirGeradorEanModal();
      return;
    }

    if (produto.produtoTipo !== ProdutoTipo.Simples &&
        !todasVariacoesPossuemCodigoBarras) {
      this.formComponent.abrirGeradorEanModal();
      return;
    }

    this.salvarProduto();
  }
  async aoAbrirModal() {
    this.formComponent.produtoForm.criarForm();
    await Promise
        .all([
          this.secaoFacade.buscarSecoes({ativo: true}),
          this.unidadeComercialFacade.buscarUnidadesComerciais({ativo: true}),
          this.empresaFacade.buscarEmpresasUsuarioLogado(
              this.authFacade.empresaLogada.matrizId),
          this.familiaFacade.buscarFamilias({ativo: true}),
          this.produtoVariacaoReferenciaFacade.buscarProdutoVariacaoReferencias(
              {ativo: true}),
          this.produtoVariacaoCorFacade.buscarProdutoVariacaoCores(
              {ativo: true}),
          this.produtoVariacaoTamanhoFacade.buscarProdutoVariacaoTamanhos(
              {ativo: true}),
          this.tabelaNutricionalFacade.buscarTabelasNutricionais({ativo: true}),
          this.areaEstoqueFacade.buscarAreasEstoque({ativo: true}),
          this.marcaFacade.buscarMarcas({ativo: true}),
        ])
        .then(() => {
          this.formComponent.produtoForm.monitorarCamposProduto();
          this.formComponent.produtoForm.setarTipoFormulario(this.produto)
              .then(() => {
                this.formComponent.gerarCodigosSku();
              });
        });
  }
  fecharModal() {
    this.formComponent.produtoForm.secoesSubscription.unsubscribe();
    this.formComponent.produtoForm.unidadesComerciaisSubscription.unsubscribe();
    this.formComponent.produtoForm.gruposSubscription.unsubscribe();
    this.formComponent.produtoForm.subgruposSubscription.unsubscribe();
    this.formComponent.produtoForm.familiasSubscription.unsubscribe();
    this.aoCancelar.emit();
  }
  ngAfterViewInit() {
    this.formComponent.criarProdutoForm();
    this.formComponent.produtoForm.criarForm();
  }
}
