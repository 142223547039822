import {NotaFiscalItem} from '@app/abstraction/domain/entities/fiscal/nota-fiscal/item.entity';
import {ProdutoVariacaoEstoque} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-estoque.entity';
import {ProdutoVariacaoPreco} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-preco.entity';
import {ProdutoVariacao} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao.entity';
import {AreaEstoqueListagem} from '@app/abstraction/domain/interfaces/estoque/area-estoque/area-estoque-listagem.interface';
import {ProdutoTipo} from '@app/shared/enums/produto-tipo.enum';

import {GrupoEconomico} from '../../shared/auth/grupo-economico.entity';

import {ProdutoTributacao} from './produto-tributacao.entity';

export class Produto {
  ativarVasilhame: boolean;
  ativo: boolean;
  bloquearVenda: boolean;
  composicaoItem: boolean;
  decomposicaoEntradaNotaFiscal: boolean;
  descricao: string;
  descricaoReduzida: string;
  etiquetaGerar: boolean;
  familiaId: string;
  grupoId: string;
  habilitarNoEcommerce: boolean;
  id: string;
  movimentarEstoque: boolean;
  ncmCodigo: string;
  ncmExcecao: string|number;
  cestCodigo: string;
  pedirDocumentoVendaPdv: boolean;
  permitirAlterarPrecoPdv: boolean;
  permitirComissao: boolean;
  permitirCotacao: boolean;
  permitirDesconto: boolean;
  permitirDescricaoComplementar: boolean;
  permitirProdutoAssociado: boolean;
  permitirTabelaNutricional: boolean;
  produtoIdAssociado: string;
  produtoPesado: boolean;
  produtoPesadoTipo: string;
  produtoTipo: ProdutoTipo;
  produtoVariacaoSimples: ProdutoVariacao;
  quantidadeEntrada: number;
  quantidadeSaida: number;
  quantidadeSaidaProdutoAssociado: number;
  secaoId: string;
  servico: boolean;
  subGrupoId: string;
  tabelaNutricionalId: string;
  unidadeComercialEntrada: string;
  unidadeComercialSaida: string;
  variacoes: ProdutoVariacao[];
  codigoSistema: string;
  codigoRapido: string;
  tributacao: ProdutoTributacao;
  diasDeValidade: number;
  createdOn: Date;
  lastModifiedOn: Date;
  marcaId: string;
  modelo: string;

  private converterValoresProduto() {
    this.ncmExcecao = Number(this.ncmExcecao);
    this.quantidadeEntrada = Number(this.quantidadeEntrada);
    this.quantidadeSaida = Number(this.quantidadeSaida);
    this.quantidadeSaidaProdutoAssociado =
        Number(this.quantidadeSaidaProdutoAssociado);
  }
  private inserirProdutoVariacaoSimples() {
    if (this.produtoTipo === ProdutoTipo.Simples) {
      this.variacoes[0] = this.produtoVariacaoSimples;
    }
  }
  private converterValoresDasVariacoes() {
    if (this.variacoes) {
      this.variacoes.forEach((variacao: ProdutoVariacao) => {
        variacao.precos.forEach(
            (produtoVariacaoPreco: ProdutoVariacaoPreco) => {
              produtoVariacaoPreco.valorPmz =
                  Number(produtoVariacaoPreco.valorPmz);
              produtoVariacaoPreco.formulaPmz =
                  Number(produtoVariacaoPreco.formulaPmz);
              produtoVariacaoPreco.margem1Fixa =
                  Number(produtoVariacaoPreco.margem1Fixa);
              produtoVariacaoPreco.valorCompra =
                  Number(produtoVariacaoPreco.valorCompra);
              produtoVariacaoPreco.valorCusto =
                  Number(produtoVariacaoPreco.valorCusto);
              produtoVariacaoPreco.formulaCusto =
                  Number(produtoVariacaoPreco.formulaCusto);
              produtoVariacaoPreco.formulaVenda =
                  Number(produtoVariacaoPreco.formulaVenda);
              produtoVariacaoPreco.margem1Praticada =
                  Number(produtoVariacaoPreco.margem1Praticada);
              produtoVariacaoPreco.valorCustoAnterior =
                  Number(produtoVariacaoPreco.valorCustoAnterior);
              produtoVariacaoPreco.valorCompraAnterior =
                  Number(produtoVariacaoPreco.valorCompraAnterior);

              produtoVariacaoPreco.margem2Fixa =
                  Number(produtoVariacaoPreco.margem2Fixa);
              produtoVariacaoPreco.margem2Praticada =
                  Number(produtoVariacaoPreco.margem2Praticada);
              produtoVariacaoPreco.margem3Fixa =
                  Number(produtoVariacaoPreco.margem3Fixa);
              produtoVariacaoPreco.margem3Praticada =
                  Number(produtoVariacaoPreco.margem3Praticada);
              produtoVariacaoPreco.margem4Fixa =
                  Number(produtoVariacaoPreco.margem4Fixa);
              produtoVariacaoPreco.margem4Praticada =
                  Number(produtoVariacaoPreco.margem4Praticada);
              produtoVariacaoPreco.valorVenda1Anterior =
                  Number(produtoVariacaoPreco.valorVenda1Anterior);
              produtoVariacaoPreco.valorVenda2 =
                  Number(produtoVariacaoPreco.valorVenda2);
              produtoVariacaoPreco.valorVenda2Anterior =
                  Number(produtoVariacaoPreco.valorVenda2Anterior);
              produtoVariacaoPreco.valorVenda3 =
                  Number(produtoVariacaoPreco.valorVenda3);
              produtoVariacaoPreco.valorVenda3Anterior =
                  Number(produtoVariacaoPreco.valorVenda3Anterior);
              produtoVariacaoPreco.valorVenda4 =
                  Number(produtoVariacaoPreco.valorVenda4);
              produtoVariacaoPreco.valorVenda4Anterior =
                  Number(produtoVariacaoPreco.valorVenda4Anterior);
            });
      });
    }
  }
  private adicionarEstoquesPadroes(areasEstoque: AreaEstoqueListagem[]) {
    if (this.variacoes) {
      this.variacoes.forEach((variacao: ProdutoVariacao) => {
        if (variacao.estoques?.length === 0) {
          variacao.estoques = [];
          areasEstoque.forEach((areaEstoque: AreaEstoqueListagem) => {
            variacao.estoques.push(
                <ProdutoVariacaoEstoque>{
                  areaEstoqueId: areaEstoque.id,
                  atual: 0,
                  congelado: 0,
                  digitado: 0,
                  maximo: 0,
                  minimo: 0,
                  variacaoId: variacao.id,
                  percentualPerda: 0,
                },
            );
          });
        }
      });
    }
  }
  static from(json: any = {}) {
    const produto = new Produto();
    produto.ativarVasilhame = json.ativarVasilhame;
    produto.ativo = json.ativo;
    produto.bloquearVenda = json.bloquearVenda;
    produto.composicaoItem = json.composicaoItem;
    produto.decomposicaoEntradaNotaFiscal = json.decomposicaoEntradaNotaFiscal;
    produto.descricao = json.descricao?.trim();
    produto.descricaoReduzida = json.descricaoReduzida?.trim();
    produto.etiquetaGerar = json.etiquetaGerar;
    produto.familiaId = json.familiaId;
    produto.grupoId = json.grupoId;
    produto.habilitarNoEcommerce = json.habilitarNoEcommerce;
    produto.movimentarEstoque = json.movimentarEstoque;
    produto.ncmCodigo = json.ncmCodigo?.trim();
    produto.ncmExcecao = json.ncmExcecao;
    produto.cestCodigo = json.cestCodigo?.trim();
    produto.pedirDocumentoVendaPdv = json.pedirDocumentoVendaPdv;
    produto.permitirAlterarPrecoPdv = json.permitirAlterarPrecoPdv;
    produto.permitirComissao = json.permitirComissao;
    produto.permitirCotacao = json.permitirCotacao;
    produto.permitirDesconto = json.permitirDesconto;
    produto.permitirDescricaoComplementar = json.permitirDescricaoComplementar;
    produto.permitirProdutoAssociado = json.permitirProdutoAssociado;
    produto.permitirTabelaNutricional = json.permitirTabelaNutricional;
    produto.id = json.id;
    produto.produtoIdAssociado = json.produtoIdAssociado;
    produto.produtoPesado = json.produtoPesado;
    produto.produtoPesadoTipo = json.produtoPesadoTipo;
    produto.produtoTipo = json.produtoTipo;
    produto.variacoes = json.variacoes;
    produto.produtoVariacaoSimples = json.produtoVariacaoSimples;
    produto.quantidadeEntrada = json.quantidadeEntrada;
    produto.quantidadeSaida = json.quantidadeSaida;
    produto.quantidadeSaidaProdutoAssociado =
        json.quantidadeSaidaProdutoAssociado ?? 0;
    produto.secaoId = json.secaoId;
    produto.servico = json.servico;
    produto.subGrupoId = json.subGrupoId;
    produto.tabelaNutricionalId = json.tabelaNutricionalId;
    produto.unidadeComercialEntrada = json.unidadeComercialEntrada?.trim();
    produto.unidadeComercialSaida = json.unidadeComercialSaida?.trim();
    produto.codigoSistema = json.codigoSistema?.trim();
    produto.codigoRapido = json.codigoRapido?.trim();
    produto.tributacao =
        json.tributacao ? ProdutoTributacao.from(json.tributacao) : null;
    produto.diasDeValidade = json.diasDeValidade;
    produto.createdOn =
        json.createdOn != null ? new Date(json.createdOn) : null;
    produto.lastModifiedOn =
        json.lastModifiedOn != null ? new Date(json.lastModifiedOn) : null;
    produto.marcaId = json.marcaId;
    produto.modelo = json.modelo;
    return produto;
  }
  static fromItemNota(
      item: NotaFiscalItem,
      grupoLogado: GrupoEconomico,
      areasEstoque: AreaEstoqueListagem[],
      unidadeComercialId: string,
      secaoId: string,
      grupoId: string,
      subgrupoId: string,
      familiaId: string,
      custoRateado: number,
  ) {
    const codigoBarras =
        item.codigoBarras === 'SEM GTIN' ? null : item.codigoBarras;

    return <Produto>{
      ativo: true,
      descricao: item.descricao.substring(0, 60),
      descricaoReduzida: item.descricao.substring(0, 35),
      produtoTipo: ProdutoTipo.Simples,
      ncmCodigo: item.ncm,
      ncmExcecao: Number(item.excecaoTipi),
      quantidadeEntrada: 1,
      quantidadeSaida: 1,
      secaoId: secaoId,
      grupoId: grupoId,
      subGrupoId: subgrupoId,
      familiaId: familiaId,
      unidadeComercialEntrada: unidadeComercialId,
      unidadeComercialSaida: unidadeComercialId,
      variacoes: [
        <ProdutoVariacao>{
          id: null,
          ativo: true,
          codigoBarras: codigoBarras,
          precos: grupoLogado.empresas.map((empresa) => <ProdutoVariacaoPreco>{
            id: null,
            empresaId: empresa.id,
            formulaCusto: 0,
            formulaPmz: 0,
            formulaVenda: 0,
            margem1Fixa: 0,
            margem1Praticada: 100,
            valorCusto: custoRateado,
            valorCompra: 0,
            valorCompraAnterior: 0,
            valorCustoAnterior: 0,
            valorPmz: 0,
            valorVenda1: custoRateado * 2,
          }),
          estoques: areasEstoque.map((areaEstoque) => <ProdutoVariacaoEstoque>{
            id: null,
            areaEstoqueId: areaEstoque.id,
            atual: 0,
            congelado: 0,
            digitado: 0,
            maximo: 0,
            minimo: 0,
            percentualPerda: 0,
            variacaoId: null,
          }),
        },
      ],
    };
  }
  toAPI(areasEstoque: AreaEstoqueListagem[]) {
    this.inserirProdutoVariacaoSimples();
    this.converterValoresProduto();
    this.converterValoresDasVariacoes();
    this.adicionarEstoquesPadroes(areasEstoque);
  }
}
