import {CommonModule, DatePipe, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ColorPickerModule} from 'ngx-color-picker';
import {NgxCurrencyDirective, provideEnvironmentNgxCurrency} from 'ngx-currency';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {ImageCropperModule} from 'ngx-image-cropper';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {MomentModule} from 'ngx-moment';
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AutoFocusModule} from 'primeng/autofocus';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BadgeModule} from 'primeng/badge';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {FileUploadModule} from 'primeng/fileupload';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MessagesModule} from 'primeng/messages';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SkeletonModule} from 'primeng/skeleton';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SplitterModule} from 'primeng/splitter';
import {StepsModule} from 'primeng/steps';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TimelineModule} from 'primeng/timeline';
import {ToastModule} from 'primeng/toast';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';

import {BarChartComponent} from './components/dumb/bar-chart/bar-chart.component';
import {BottomProgressBarComponent} from './components/dumb/bottom-progress-bar/bottom-progress-bar.component';
import {CartaoContaBoletoCarteiraComponent} from './components/dumb/cartao-conta-boleto-carteira/cartao-conta-boleto-carteira.component';
import {CartaoContatoComponent} from './components/dumb/cartao-contato/cartao-contato.component';
import {CartaoEnderecoComponent} from './components/dumb/cartao-endereco/cartao-endereco.component';
import {CheckboxGrupoComponent} from './components/dumb/checkbox-grupo/checkbox-grupo.component';
import {CustomLabelComponent} from './components/dumb/custom-label/custom-label.component';
import {AutoCompleteInputComponent} from './components/dumb/form-inputs/auto-complete-input/auto-complete-input.component';
import {CalendarInputComponent} from './components/dumb/form-inputs/calendar-input/calendar-input.component';
import {CheckboxInputComponent} from './components/dumb/form-inputs/checkbox-input/checkbox-input.component';
import {DropdownInputComponent} from './components/dumb/form-inputs/dropdown-input/dropdown-input.component';
import {MultiselectInputComponent} from './components/dumb/form-inputs/multiselect-input/multiselect-input.component';
import {NumericInputComponent} from './components/dumb/form-inputs/numeric-input/numeric-input.component';
import {PasswordInputComponent} from './components/dumb/form-inputs/password-input/password-input.component';
import {TextInputComponent} from './components/dumb/form-inputs/text-input/text-input.component';
import {TextAreaInputComponent} from './components/dumb/form-inputs/textarea-input/textarea-input.component';
import {TextGroupInputComponent} from './components/dumb/form-inputs/textgroup-input/textgroup-input.component';
import {HeaderPrimeiroAcessoComponent} from './components/dumb/header-primeiro-acesso/header-primeiro-acesso.component';
import {IconPickerComponent} from './components/dumb/icon-picker/icon-picker.component';
import {ImagemOrderListComponent} from './components/dumb/imagem-order-list/imagem-order-list.component';
import {ListTileComponent} from './components/dumb/list-tile/list-tile.component';
import {ListaErrosComponent} from './components/dumb/lista-erros/lista-erros.component';
import {MenuAcoesMassaComponent} from './components/dumb/menu-acoes-massa/menu-acoes-massa.component';
import {MenuTopComponent} from './components/dumb/menu-top/menu-top.component';
import {MultiSelectColunasComponent} from './components/dumb/multi-select-colunas/multi-select-colunas.component';
import {AutoCompleteInputNgModelComponent} from './components/dumb/ngmodel-inputs/auto-complete-input-ngmodel/auto-complete-input-ngmodel.component';
import {CalendarInputNgModelComponent} from './components/dumb/ngmodel-inputs/calendar-input-ngmodel/calendar-input-ngmodel.component';
import {CheckboxInputNgModelComponent} from './components/dumb/ngmodel-inputs/checkbox-input-ngmodel/checkbox-input-ngmodel.component';
import {DropdownInputNgmodelComponent as DropdownInputNgModelComponent} from './components/dumb/ngmodel-inputs/dropdown-input-ngmodel/dropdown-input-ngmodel.component';
import {MultiselectInputNgModelComponent} from './components/dumb/ngmodel-inputs/multiselect-input-ngmodel/multiselect-input-ngmodel.component';
import {NumericInputNgModelComponent as NumericoInputNgModelComponent} from './components/dumb/ngmodel-inputs/numeric-input-ngmodel/numeric-input-ngmodel.component';
import {StepNumericInputComponent} from './components/dumb/ngmodel-inputs/step-numeric-input/step-numeric-input.component';
import {TextInputNgModelComponent} from './components/dumb/ngmodel-inputs/text-input-ngmodel/text-input-ngmodel.component';
import {TextAreaInputNgModelComponent} from './components/dumb/ngmodel-inputs/textarea-input-ngmodel/textarea-input-ngmodel.component';
import {ProgressBarComponent} from './components/dumb/progress-bar/progress-bar.component';
import {SeletorTipoPessoaCadastroComponent} from './components/dumb/seletor-tipo-pessoa-cadastro/seletor-tipo-pessoa-cadastro.component';
import {CampoFormSkeletonComponent} from './components/dumb/skeletons/campo-form-skeleton/campo-form-skeleton.component';
import {MenuTopSkeletonComponent} from './components/dumb/skeletons/menu-top-skeleton/menu-top-skeleton.component';
import {ProdutoEmpresaSkeletonComponent} from './components/dumb/skeletons/produto-empresa-skeleton/produto-empresa-skeleton.component';
import {TableSkeletonComponent} from './components/dumb/skeletons/table-skeleton/table-skeleton.component';
import {TabelaParametrosComponent} from './components/dumb/tabela-parametros/tabela-parametros.component';
import {TabelaPlanosComponent} from './components/dumb/tabela-planos/tabela-planos.component';
import {TableComponent} from './components/dumb/table/table.component';
import {WidgetListComponent} from './components/dumb/widget-list/widget-list.component';
import {ClienteSharedFormComponent} from './components/shared-forms/cliente-shared-form/cliente-shared-form.component';
import {ContaBancariaSharedFormComponent} from './components/shared-forms/conta-bancaria-shared-form/conta-bancaria-shared-form.component';
import {FornecedorSharedFormComponent} from './components/shared-forms/fornecedor-shared-form/fornecedor-shared-form.component';
import {NcmSharedFormComponent} from './components/shared-forms/ncm-shared-form/ncm-shared-form.component';
import {ProdutoSharedFormComponent} from './components/shared-forms/produto-shared-form/produto-shared-form.component';
import {TransportadoraSharedFormComponent} from './components/shared-forms/transportadora-shared-form/transportadora-shared-form.component';
import {CestBuscaRapidaComponent} from './components/smart/buscas-rapidas/cest-busca-rapida/cest-busca-rapida.component';
import {ClienteBuscaRapidaComponent} from './components/smart/buscas-rapidas/cliente-busca-rapida/cliente-busca-rapida.component';
import {EnderecoBuscaRapidaComponent} from './components/smart/buscas-rapidas/endereco-busca-rapida/endereco-busca-rapida.component';
import {FornecedorBuscaRapidaComponent} from './components/smart/buscas-rapidas/fornecedor-busca-rapida/fornecedor-busca-rapida.component';
import {FuncionarioBuscaRapidaComponent} from './components/smart/buscas-rapidas/funcionario-busca-rapida/funcionario-busca-rapida.component';
import {LancamentoParcelaBuscaRapidaComponent} from './components/smart/buscas-rapidas/lancamentos-parcelas-busca-rapida/lancamento-parcela-busca-rapida.component';
import {ManisfestadorBuscaRapidaComponent} from './components/smart/buscas-rapidas/manifestador-busca-rapida/manifestador-busca-rapida.component';
import {NaturezaReceitaBuscaRapidaComponent} from './components/smart/buscas-rapidas/natureza-receita-busca-rapida/natureza-receita-busca-rapida.component';
import {NcmBuscaRapidaComponent} from './components/smart/buscas-rapidas/ncm-busca-rapida/ncm-busca-rapida.component';
import {NotaFiscalEntradaBuscaRapidaComponent} from './components/smart/buscas-rapidas/nota-fiscal-entrada-busca-rapida/nota-fiscal-entrada-busca-rapida.component';
import {NotaFiscalSaidaBuscaRapidaComponent} from './components/smart/buscas-rapidas/nota-fiscal-saida-busca-rapida/nota-fiscal-saida-busca-rapida.component';
import {PessoaBuscaRapidaComponent} from './components/smart/buscas-rapidas/pessoa-busca-rapida/pessoa-busca-rapida.component';
import {ProdutoBuscaRapidaComponent} from './components/smart/buscas-rapidas/produto-busca-rapida/produto-busca-rapida.component';
import {ProdutoVariacaoBuscaRapidaComponent} from './components/smart/buscas-rapidas/produto-variacao-busca-rapida/produto-variacao-busca-rapida.component';
import {RegraFiscalBuscaRapidaComponent} from './components/smart/buscas-rapidas/regra-fiscal-busca-rapida/regra-fiscal-busca-rapida.component';
import {TransportadoraBuscaRapidaComponent} from './components/smart/buscas-rapidas/transportadora-busca-rapida/transportadora-busca-rapida.component';
import {BancoCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/banco-cadastro-rapido-modal/banco-cadastro-rapido-modal.component';
import {ClienteCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/cliente-cadastro-rapido-modal/cliente-cadastro-rapido-modal.component';
import {ClienteSituacaoCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/cliente-situacao-cadastro-rapido-modal/cliente-situacao-cadastro-rapido-modal.component';
import {ContaBancariaCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/conta-bancaria-cadastro-rapido-modal/conta-bancaria-cadastro-rapido-modal.component';
import {FamiliaCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/familia-cadastro-rapido-modal/familia-cadastro-rapido-modal.component';
import {FormaPagamentoCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/forma-pagamento-cadastro-rapido-modal/forma-pagamento-cadastro-rapido-modal.component';
import {FormaParcelamentoCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/forma-parcelamento-cadastro-rapido-modal/forma-parcelamento-cadastro-rapido-modal.component';
import {FornecedorCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/fornecedor-cadastro-rapido-modal/fornecedor-cadastro-rapido-modal.component';
import {GrupoCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/grupo-cadastro-rapido-modal/grupo-cadastro-rapido-modal.component';
import {MarcaCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/marca-cadastro-rapido-modal/marca-cadastro-rapido-modal.component';
import {NaturezaReceitaCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/natureza-receita-cadastro-rapido-modal/natureza-receita-cadastro-rapido-modal.component';
import {NcmCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/ncm-cadastro-rapido-modal/ncm-cadastro-rapido-modal.component';
import {PlanoContasCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/plano-contas-cadastro-rapido-modal/plano-contas-cadastro-rapido-modal.component';
import {ProdutoCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/produto-cadastro-rapido-modal/produto-cadastro-rapido-modal.component';
import {ProdutoVariacaoCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/produto-variacao-cadastro-rapido-modal/produto-variacao-cadastro-rapido-modal.component';
import {ProdutoVariacaoCorCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/produto-variacao-cor-cadastro-rapido-modal/produto-variacao-cor-cadastro-rapido-modal.component';
import {ProdutoVariacaoReferenciaCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/produto-variacao-referencia-cadastro-rapido-modal/produto-variacao-referencia-cadastro-rapido-modal.component';
import {ProdutoVariacaoTamanhoCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/produto-variacao-tamanho-cadastro-rapido-modal/produto-variacao-tamanho-cadastro-rapido-modal.component';
import {SecaoCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/secao-cadastro-rapido-modal/secao-cadastro-rapido-modal.component';
import {SubgrupoCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/subgrupo-cadastro-rapido-modal/subgrupo-cadastro-rapido-modal.component';
import {TabelaNutricionalCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/tabela-nutricional-cadastro-rapido-modal/tabela-nutricional-cadastro-rapido-modal.component';
import {TipoAjusteCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/tipo-ajuste-cadastro-rapido-modal/tipo-ajuste-cadastro-rapido-modal.component';
import {TransportadoraCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/transportadora-cadastro-rapido-modal/transportadora-cadastro-rapido-modal.component';
import {UnidadeComercialCadastroRapidoModalComponent} from './components/smart/cadastros-rapidos/unidade-comercial-cadastro-rapido-modal/unidade-comercial-cadastro-rapido-modal.component';
import {ContaBancariaBoletoCarteiraModalComponent} from './components/smart/conta-bancaria-boleto-carteira-modal/conta-bancaria-boleto-carteira-modal.component';
import {ContasListaPagamentoParcialComponent} from './components/smart/contas-lista-pagamento-parcial/contas-lista-pagamento-parcial.component';
import {ContasPagamentoMassaModalComponent} from './components/smart/contas-pagamento-massa-modal/contas-pagamento-massa-modal.component';
import {ContasPagamentoParcialModalComponent} from './components/smart/contas-pagamento-parcial-modal/contas-pagamento-parcial-modal.component';
import {ContasRenegociacaoModalComponent} from './components/smart/contas-renegociacao-modal/contas-renegociacao-modal.component';
import {ContatoModalComponent} from './components/smart/contato-modal/contato-modal.component';
import {PedidoOpcoesComponent} from './components/smart/dynamic-dialogs/pedido-opcoes/pedido-opcoes.component';
import {EnderecoModalComponent} from './components/smart/endereco-modal/endereco-modal.component';
import {HeaderComponent} from './components/smart/header/header.component';
import {NotificacoesComponent} from './components/smart/notificacoes/notificacoes.component';
import {RightSidebarComponent} from './components/smart/right-sidebar/right-sidebar.component';
// import {SidebarComponent} from
// './components/smart/sidebar/sidebar.component';
import {TituloComponent} from './components/smart/titulo/titulo.component';
import {WidgetFavoritosComponent} from './components/smart/widget-favoritos/widget-favoritos.component';
import {AutoFocusDirective} from './directives/auto-focus/auto-focus.directive';
import {CalendarMaskDirective} from './directives/calendar-mask/calendar-mask.directive';
import {FoneMaskDirective} from './directives/fone-mask/fone-mask.directive';
import {NcmMaskDirective} from './directives/ncm-mask/ncm-mask.directive';
import {TabScrollerDirective} from './directives/tab-scroller/tab-scroller.directive';
import {UppercaseDirective} from './directives/uppercase-value-accessor/uppercase.directive';
import {AdministradoraPipe} from './pipes/administradora/administradora.pipe';
import {AreaEstoquePipe} from './pipes/area-estoque/area-estoque.pipe';
import {BancoPipe} from './pipes/banco/banco.pipe';
import {BooleanoPipe} from './pipes/booleano/booleano.pipe';
import {CarteiraPipe} from './pipes/carteira/carteira.pipe';
import {CepPipe} from './pipes/cep/cep.pipe';
import {ClienteSituacaoPipe} from './pipes/cliente-situacao/cliente-situacao.pipe';
import {ContaBancariaPipe} from './pipes/conta-bancaria/conta-bancaria.pipe';
import {CorPipe} from './pipes/cor/cor.pipe';
import {CustomCurrencyPipe} from './pipes/custom-currency/custom-currency.pipe';
import {customDecimalPipe} from './pipes/custom-number/custom-decimal.pipe';
import {DateTimeAgoPipe} from './pipes/date-time-ago/date-time-ago.pipe';
import {DocumentoPipe} from './pipes/documento/documento.pipe';
import {EmpresaPipe} from './pipes/empresa/empresa.pipe';
import {EnderecoTipoPipe} from './pipes/endereco-tipo/endereco-tipo.pipe';
import {EnumLabelPipe} from './pipes/enum-label/enum-label.pipe';
import {EstadoPipe} from './pipes/estado/estado.pipe';
import {FamiliaPipe} from './pipes/familia/familia.pipe';
import {FonePipe} from './pipes/fone/fone.pipe';
import {FormaPagamentoPipe} from './pipes/forma-pagamento/forma-pagamento.pipe';
import {FormaParcelamentoPipe} from './pipes/forma-parcelamento/forma-parcelamento.pipe';
import {FornecedorPipe} from './pipes/fornecedor/fornecedor.pipe';
import {GrupoPipe} from './pipes/grupo/grupo.pipe';
import {LocaleDatePipe} from './pipes/locale-date/locale-date.pipe';
import {MarcaPipe} from './pipes/marca/marca.pipe';
import {PaisPipe} from './pipes/pais/pais.pipe';
import {PerfilPipe} from './pipes/perfil/perfil.pipe';
import {PessoaPipe} from './pipes/pessoa/pessoa.pipe';
import {PessoaPorIdPipe} from './pipes/pessoaPorId/pessoa-por-id.pipe';
import {PlanoContas3Pipe} from './pipes/plano-contas-3/plano-contas-3.pipe';
import {PorcentagemPipe} from './pipes/porcentagem/porcentagem.pipe';
import {ProdutoCcsApuradaDescricaoPipe} from './pipes/produto-ccs-apurada-descricao/produto-ccs-apurada-descricao.pipe';
import {ProdutoCstDescricaoPipe} from './pipes/produto-cst-descricao/produto-cst-descricao.pipe';
import {ProdutoCstIpiDescricaoPipe} from './pipes/produto-cst-ipi-descricao/produto-cst-ipi-descricao.pipe';
import {ProdutoCstPisCofinsDescricaoPipe} from './pipes/produto-cst-pis-cofins-descricao/produto-cst-pis-cofins-descricao.pipe';
import {ProdutoTipoPisCofinsDescricaoPipe} from './pipes/produto-tipo-pis-cofins-descricao/produto-tipo-pis-cofins-descricao.pipe';
import {ProdutoPipe} from './pipes/produto/produto.pipe';
import {ReferenciaPipe} from './pipes/referencia/referencia.pipe';
import {SecaoPipe} from './pipes/secao/secao.pipe';
import {StringPadPipe} from './pipes/string-pad/string-pad.pipe';
import {SubgrupoPipe} from './pipes/subgrupo/subgrupo.pipe';
import {TamanhoPipe} from './pipes/tamanho/tamanho.pipe';
import {TipoPagamentoNfeDescricaoPipe} from './pipes/tipo-pagamento-nfe-descricao/tipo-pagamento-nfe-descricao.pipe';
import {UsuarioPipe} from './pipes/usuario/usuario.pipe';

@NgModule({
  imports: [...SharedModule.importModules()],
  declarations: [
    ...SharedModule.declareComponents(),
    ...SharedModule.declareDirectives(),
    ...SharedModule.declarePipes(),
  ],
  exports: [
    ...SharedModule.exportModules(),
    ...SharedModule.exportComponents(),
    ...SharedModule.exportDirectives(),
    ...SharedModule.exportPipes(),
  ],
  providers: [
    ...SharedModule.providePipes(),
    provideNgxMask(),
    provideEnvironmentNgxCurrency({
      align: 'right',
      allowNegative: true,
      allowZero: true,
      decimal: ',',
      precision: 2,
      prefix: '',
      suffix: '',
      thousands: '.',
      nullable: true,
    }),
  ],
})
export class SharedModule {
  static importModules() {
    return [
      AccordionModule,
      AutoCompleteModule,
      AutoFocusModule,
      AvatarGroupModule,
      AvatarModule,
      BadgeModule,
      BreadcrumbModule,
      ButtonModule,
      CalendarModule,
      CardModule,
      ChartModule,
      CheckboxModule,
      ColorPickerModule,
      CommonModule,
      ConfirmDialogModule,
      DialogModule,
      DividerModule,
      DropdownModule,
      DynamicDialogModule,
      FileUploadModule,
      FormsModule,
      ImageCropperModule,
      InfiniteScrollModule,
      InputMaskModule,
      InputNumberModule,
      InputSwitchModule,
      InputTextModule,
      InputTextareaModule,
      ListboxModule,
      MegaMenuModule,
      MenuModule,
      MessagesModule,
      MomentModule.forRoot(),
      MultiSelectModule,
      NgxCurrencyDirective,
      NgxExtendedPdfViewerModule,
      NgxJsonViewerModule,
      NgxMaskDirective,
      NgxMaskPipe,
      OrderListModule,
      OverlayPanelModule,
      PanelMenuModule,
      PanelModule,
      PasswordModule,
      RadioButtonModule,
      ReactiveFormsModule,
      ScrollTopModule,
      SelectButtonModule,
      SidebarModule,
      SkeletonModule,
      SplitButtonModule,
      SplitterModule,
      StepsModule,
      TabViewModule,
      TableModule,
      TagModule,
      TieredMenuModule,
      TimelineModule,
      ToastModule,
      ToolbarModule,
      TooltipModule,
      TreeModule,
      TreeTableModule,
      TriStateCheckboxModule,
    ];
  }
  static declareComponents() {
    return [
      AutoCompleteInputComponent,
      AutoCompleteInputNgModelComponent,
      BancoCadastroRapidoModalComponent,
      BarChartComponent,
      BottomProgressBarComponent,
      CalendarInputComponent,
      CalendarInputNgModelComponent,
      CampoFormSkeletonComponent,
      CartaoContaBoletoCarteiraComponent,
      CartaoContatoComponent,
      CartaoEnderecoComponent,
      CestBuscaRapidaComponent,
      CheckboxGrupoComponent,
      CheckboxInputComponent,
      CheckboxInputNgModelComponent,
      ClienteBuscaRapidaComponent,
      ClienteCadastroRapidoModalComponent,
      ClienteSharedFormComponent,
      FornecedorSharedFormComponent,
      ClienteSituacaoCadastroRapidoModalComponent,
      ContaBancariaBoletoCarteiraModalComponent,
      ContaBancariaCadastroRapidoModalComponent,
      ContaBancariaSharedFormComponent,
      ContasListaPagamentoParcialComponent,
      ContasPagamentoMassaModalComponent,
      ContasPagamentoParcialModalComponent,
      ContasRenegociacaoModalComponent,
      ContatoModalComponent,
      CustomLabelComponent,
      DropdownInputComponent,
      DropdownInputNgModelComponent,
      EnderecoBuscaRapidaComponent,
      EnderecoModalComponent,
      FamiliaCadastroRapidoModalComponent,
      MarcaCadastroRapidoModalComponent,
      FormaPagamentoCadastroRapidoModalComponent,
      FormaParcelamentoCadastroRapidoModalComponent,
      FornecedorBuscaRapidaComponent,
      FornecedorCadastroRapidoModalComponent,
      FuncionarioBuscaRapidaComponent,
      GrupoCadastroRapidoModalComponent,
      HeaderComponent,
      HeaderPrimeiroAcessoComponent,
      IconPickerComponent,
      ImagemOrderListComponent,
      ListTileComponent,
      ListaErrosComponent,
      ManisfestadorBuscaRapidaComponent,
      MenuAcoesMassaComponent,
      MenuTopComponent,
      MenuTopSkeletonComponent,
      MultiSelectColunasComponent,
      MultiselectInputComponent,
      MultiselectInputNgModelComponent,
      NaturezaReceitaBuscaRapidaComponent,
      NaturezaReceitaCadastroRapidoModalComponent,
      NcmBuscaRapidaComponent,
      NcmCadastroRapidoModalComponent,
      NcmSharedFormComponent,
      NotaFiscalEntradaBuscaRapidaComponent,
      NotaFiscalSaidaBuscaRapidaComponent,
      NotificacoesComponent,
      NumericInputComponent,
      NumericoInputNgModelComponent,
      PasswordInputComponent,
      PedidoOpcoesComponent,
      PessoaBuscaRapidaComponent,
      PlanoContasCadastroRapidoModalComponent,
      ProdutoBuscaRapidaComponent,
      ProdutoCadastroRapidoModalComponent,
      ProdutoEmpresaSkeletonComponent,
      ProdutoSharedFormComponent,
      ProdutoVariacaoBuscaRapidaComponent,
      ProdutoVariacaoCadastroRapidoModalComponent,
      ProdutoVariacaoCorCadastroRapidoModalComponent,
      ProdutoVariacaoReferenciaCadastroRapidoModalComponent,
      ProdutoVariacaoTamanhoCadastroRapidoModalComponent,
      ProgressBarComponent,
      RegraFiscalBuscaRapidaComponent,
      RightSidebarComponent,
      SecaoCadastroRapidoModalComponent,
      SeletorTipoPessoaCadastroComponent,
      // SidebarComponent,
      StepNumericInputComponent,
      SubgrupoCadastroRapidoModalComponent,
      TabelaNutricionalCadastroRapidoModalComponent,
      TabelaParametrosComponent,
      TabelaPlanosComponent,
      TableComponent,
      TableSkeletonComponent,
      TextAreaInputComponent,
      TextInputComponent,
      TextInputNgModelComponent,
      TipoAjusteCadastroRapidoModalComponent,
      TituloComponent,
      TransportadoraBuscaRapidaComponent,
      TransportadoraSharedFormComponent,
      UnidadeComercialCadastroRapidoModalComponent,
      WidgetFavoritosComponent,
      WidgetListComponent,
      TransportadoraCadastroRapidoModalComponent,
      LancamentoParcelaBuscaRapidaComponent,
      TextAreaInputNgModelComponent,
      TextGroupInputComponent,
    ];
  }
  static declareDirectives() {
    return [
      AutoFocusDirective,
      CalendarMaskDirective,
      FoneMaskDirective,
      NcmMaskDirective,
      TabScrollerDirective,
      UppercaseDirective,
    ];
  }
  static declarePipes() {
    return [
      AdministradoraPipe,
      AreaEstoquePipe,
      BancoPipe,
      BooleanoPipe,
      CarteiraPipe,
      CepPipe,
      ClienteSituacaoPipe,
      ContaBancariaPipe,
      CorPipe,
      DocumentoPipe,
      CustomCurrencyPipe,
      DateTimeAgoPipe,
      EmpresaPipe,
      EnderecoTipoPipe,
      EnumLabelPipe,
      EstadoPipe,
      FamiliaPipe,
      FonePipe,
      FormaPagamentoPipe,
      FormaParcelamentoPipe,
      FornecedorPipe,
      GrupoPipe,
      LocaleDatePipe,
      PaisPipe,
      PerfilPipe,
      PessoaPipe,
      PessoaPorIdPipe,
      PlanoContas3Pipe,
      PorcentagemPipe,
      ProdutoCcsApuradaDescricaoPipe,
      ProdutoCstDescricaoPipe,
      ProdutoCstIpiDescricaoPipe,
      ProdutoCstPisCofinsDescricaoPipe,
      ProdutoPipe,
      ProdutoTipoPisCofinsDescricaoPipe,
      ReferenciaPipe,
      MarcaPipe,
      SecaoPipe,
      StringPadPipe,
      SubgrupoPipe,
      TamanhoPipe,
      TipoPagamentoNfeDescricaoPipe,
      UsuarioPipe,
      customDecimalPipe,
    ];
  }
  static exportModules() {
    return [
      AccordionModule,
      AutoCompleteModule,
      AutoFocusModule,
      AvatarGroupModule,
      AvatarModule,
      BadgeModule,
      BreadcrumbModule,
      ButtonModule,
      CalendarModule,
      CardModule,
      ChartModule,
      CheckboxModule,
      ColorPickerModule,
      CommonModule,
      ConfirmDialogModule,
      DialogModule,
      DividerModule,
      DropdownModule,
      DynamicDialogModule,
      FileUploadModule,
      FormsModule,
      ImageCropperModule,
      InfiniteScrollModule,
      InputMaskModule,
      InputNumberModule,
      InputSwitchModule,
      InputTextModule,
      InputTextareaModule,
      ListboxModule,
      MegaMenuModule,
      MenuModule,
      MessagesModule,
      MomentModule,
      MultiSelectModule,
      NgxExtendedPdfViewerModule,
      NgxJsonViewerModule,
      NgxMaskDirective,
      NgxMaskPipe,
      OrderListModule,
      OverlayPanelModule,
      PanelMenuModule,
      PanelModule,
      PasswordModule,
      RadioButtonModule,
      ReactiveFormsModule,
      ScrollTopModule,
      SelectButtonModule,
      SidebarModule,
      SkeletonModule,
      SplitButtonModule,
      SplitterModule,
      StepsModule,
      TabViewModule,
      TableModule,
      TagModule,
      TieredMenuModule,
      TimelineModule,
      ToastModule,
      ToolbarModule,
      TooltipModule,
      TranslateModule,
      TreeModule,
      TreeTableModule,
      TriStateCheckboxModule,
    ];
  }
  static exportComponents() {
    return [
      AutoCompleteInputComponent,
      AutoCompleteInputNgModelComponent,
      BancoCadastroRapidoModalComponent,
      BarChartComponent,
      BottomProgressBarComponent,
      CalendarInputComponent,
      CalendarInputNgModelComponent,
      CampoFormSkeletonComponent,
      CartaoContaBoletoCarteiraComponent,
      CartaoContatoComponent,
      CartaoEnderecoComponent,
      CestBuscaRapidaComponent,
      CheckboxGrupoComponent,
      CheckboxInputComponent,
      CheckboxInputNgModelComponent,
      ClienteBuscaRapidaComponent,
      ClienteCadastroRapidoModalComponent,
      ClienteSharedFormComponent,
      FornecedorSharedFormComponent,
      ClienteSituacaoCadastroRapidoModalComponent,
      ContaBancariaBoletoCarteiraModalComponent,
      ContaBancariaCadastroRapidoModalComponent,
      ContaBancariaSharedFormComponent,
      ContasListaPagamentoParcialComponent,
      ContasPagamentoMassaModalComponent,
      ContasPagamentoParcialModalComponent,
      ContasRenegociacaoModalComponent,
      ContatoModalComponent,
      CustomLabelComponent,
      DropdownInputComponent,
      DropdownInputNgModelComponent,
      EnderecoBuscaRapidaComponent,
      EnderecoModalComponent,
      FamiliaCadastroRapidoModalComponent,
      MarcaCadastroRapidoModalComponent,
      FormaPagamentoCadastroRapidoModalComponent,
      FormaParcelamentoCadastroRapidoModalComponent,
      FornecedorBuscaRapidaComponent,
      FornecedorCadastroRapidoModalComponent,
      FuncionarioBuscaRapidaComponent,
      GrupoCadastroRapidoModalComponent,
      HeaderComponent,
      HeaderPrimeiroAcessoComponent,
      IconPickerComponent,
      ImagemOrderListComponent,
      ListTileComponent,
      ListaErrosComponent,
      ManisfestadorBuscaRapidaComponent,
      MenuAcoesMassaComponent,
      MenuTopComponent,
      MenuTopSkeletonComponent,
      MultiSelectColunasComponent,
      MultiselectInputComponent,
      MultiselectInputNgModelComponent,
      NaturezaReceitaBuscaRapidaComponent,
      NaturezaReceitaCadastroRapidoModalComponent,
      NcmBuscaRapidaComponent,
      NcmCadastroRapidoModalComponent,
      NcmSharedFormComponent,
      NotaFiscalEntradaBuscaRapidaComponent,
      NotaFiscalSaidaBuscaRapidaComponent,
      NotificacoesComponent,
      NumericInputComponent,
      NumericoInputNgModelComponent,
      PasswordInputComponent,
      PedidoOpcoesComponent,
      PessoaBuscaRapidaComponent,
      PlanoContasCadastroRapidoModalComponent,
      ProdutoBuscaRapidaComponent,
      ProdutoCadastroRapidoModalComponent,
      ProdutoEmpresaSkeletonComponent,
      ProdutoSharedFormComponent,
      ProdutoVariacaoBuscaRapidaComponent,
      ProdutoVariacaoCadastroRapidoModalComponent,
      ProdutoVariacaoCorCadastroRapidoModalComponent,
      ProdutoVariacaoReferenciaCadastroRapidoModalComponent,
      ProdutoVariacaoTamanhoCadastroRapidoModalComponent,
      ProgressBarComponent,
      RegraFiscalBuscaRapidaComponent,
      RightSidebarComponent,
      SecaoCadastroRapidoModalComponent,
      SeletorTipoPessoaCadastroComponent,
      // SidebarComponent,
      StepNumericInputComponent,
      SubgrupoCadastroRapidoModalComponent,
      TabelaNutricionalCadastroRapidoModalComponent,
      TabelaParametrosComponent,
      TabelaPlanosComponent,
      TableComponent,
      TableSkeletonComponent,
      TextAreaInputComponent,
      TextInputComponent,
      TextInputNgModelComponent,
      TipoAjusteCadastroRapidoModalComponent,
      TituloComponent,
      TransportadoraBuscaRapidaComponent,
      TransportadoraSharedFormComponent,
      UnidadeComercialCadastroRapidoModalComponent,
      WidgetFavoritosComponent,
      WidgetListComponent,
      TransportadoraCadastroRapidoModalComponent,
      TextAreaInputNgModelComponent,
      TextGroupInputComponent,
      LancamentoParcelaBuscaRapidaComponent,
    ];
  }
  static exportDirectives() {
    return [
      AutoFocusDirective,
      CalendarMaskDirective,
      FoneMaskDirective,
      NcmMaskDirective,
      TabScrollerDirective,
      UppercaseDirective,
    ];
  }
  static exportPipes() {
    return [
      AdministradoraPipe,
      AreaEstoquePipe,
      BancoPipe,
      BooleanoPipe,
      CarteiraPipe,
      CepPipe,
      ClienteSituacaoPipe,
      ContaBancariaPipe,
      CorPipe,
      DocumentoPipe,
      CustomCurrencyPipe,
      DateTimeAgoPipe,
      EmpresaPipe,
      EnderecoTipoPipe,
      EnumLabelPipe,
      EstadoPipe,
      FamiliaPipe,
      FonePipe,
      FormaPagamentoPipe,
      FormaParcelamentoPipe,
      FornecedorPipe,
      GrupoPipe,
      LocaleDatePipe,
      PaisPipe,
      PerfilPipe,
      PessoaPipe,
      PessoaPorIdPipe,
      PlanoContas3Pipe,
      PorcentagemPipe,
      ProdutoCcsApuradaDescricaoPipe,
      ProdutoCstDescricaoPipe,
      ProdutoCstIpiDescricaoPipe,
      ProdutoCstPisCofinsDescricaoPipe,
      ProdutoPipe,
      ProdutoTipoPisCofinsDescricaoPipe,
      ReferenciaPipe,
      MarcaPipe,
      SecaoPipe,
      StringPadPipe,
      SubgrupoPipe,
      TamanhoPipe,
      TipoPagamentoNfeDescricaoPipe,
      UsuarioPipe,
      customDecimalPipe,
    ];
  }
  static providePipes() {
    return [
      AdministradoraPipe,
      BancoPipe,
      BooleanoPipe,
      CarteiraPipe,
      ContaBancariaPipe,
      DocumentoPipe,
      CustomCurrencyPipe,
      DatePipe,
      DecimalPipe,
      LocaleDatePipe,
      PaisPipe,
      PlanoContas3Pipe,
      PorcentagemPipe,
      ProdutoCcsApuradaDescricaoPipe,
      ProdutoCstDescricaoPipe,
      ProdutoCstIpiDescricaoPipe,
      ProdutoCstPisCofinsDescricaoPipe,
      ProdutoTipoPisCofinsDescricaoPipe,
      customDecimalPipe,
    ];
  }
}
